import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import { useFlexSearch } from "react-use-flexsearch"
import { Formik, Form, Field } from "formik"

import LayoutEnglish from "../components/layoutEnglish"
import Seo from "../components/seo"

// const index = data.localSearchStories.index
// const store = data.localSearchStories.store

const SearchBar = ({ index, store }) => {
  const [query, setQuery] = useState(null)
  const results = useFlexSearch(query, index, store)

  return (
    <div>
      <Formik
        initialValues={{ query: '' }}
        onSubmit={(values, { setSubmitting }) => {
          setQuery(values.query)
          setSubmitting(false)
        }}
      >
        <Form style={{ paddingBottom: `80px` }}>
          Search: <Field name="query" />
          <button type="submit">Submit</button>
        </Form>
      </Formik>
      { 
        results.length > 0 &&
        <>
          <h1>Searchresults</h1>
          <ul>
            {results.map(result => (
              <li key={result.id}><Link to={`../${result.slug.toLowerCase().split('.')[0]}-en`}>{result.code} - {result.title}</Link></li>
            ))}
          </ul>
        </> 
      }
    </div>
  )
}

const SearchPage = ({ data }) => (
  <LayoutEnglish>
    <Seo title="City Stories" />
    <h1>City Stories Suche</h1>
    <SearchBar
      index={ data.localSearchStories.index }
      store={ data.localSearchStories.store }
    />
    <p>{ console.log(data) }</p>
  </LayoutEnglish>
)

export const query = graphql`
  query 
  {
    localSearchStories {
      index
      store
    }
  }
`

export default SearchPage
